html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media (max-width: 1250px) {
  html {
    font-size: 58.25%;
  }
}
@media (max-width: 1110px) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 865px) {
  html {
    font-size: 45%;
  }
}
@media (max-width: 575px) {
  html {
    font-size: 30%;
  }
}
@media (min-width: 1800px) {
  html {
    font-size: 75%;
  }
}

*, ::after, ::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fafafa;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  color: #fafafa;
  margin: 0 auto;
}

.container {
  background-color: #f7f7f7;
  position: relative;
  z-index: 0;
}

@media (max-width: 1110px) {
  .slick-dots {
    bottom: -5rem !important;
  }
}

.slick-dots li button:before {
  font-size: 1.2rem;
  color: #BCE6FA;
  opacity: 1;
  margin-top: 1rem;
}
@media (max-width: 1110px) {
  .slick-dots li button:before {
    margin-top: -2rem;
  }
}
@media (max-width: 865px) {
  .slick-dots li button:before {
    margin-top: 0rem;
  }
}
@media (max-width: 575px) {
  .slick-dots li button:before {
    margin-top: -2rem;
  }
}

.slick-next, .slick-prev {
  top: 63%;
}

.slick-next:before, .slick-prev:before {
  font-size: 3rem;
  opacity: 0.5;
}

.slick-slide {
  -webkit-backface-visibility: hidden;
}

@media (max-width: 865px) {
  .clients__slider div {
    grid-area: inherit !important;
  }
}

button.slick-next:before {
  content: url("./public/icons/left-arrow2.png");
}

button.slick-prev:before {
  content: url("./public/icons/right-arrow2.png");
}

.title-first {
  font-size: 7.2rem;
  font-weight: 700;
  line-height: 7.3rem;
}
@media (max-width: 1110px) {
  .title-first {
    font-size: 5.2rem;
  }
}
.title-first_black {
  color: #000000;
}
.title-first_white {
  color: #fff;
}
.title-first_blue {
  color: #1A759F;
}

.title-secondary {
  font-size: 3.6rem;
  line-height: 4rem;
  font-weight: 600;
}
.title-secondary_black {
  color: #000000;
}
.title-secondary_white {
  color: #fff;
}

.title-third {
  font-size: 2.4rem;
  font-weight: 600;
}
.title-third_black {
  color: #000000;
}
.title-third_white {
  color: #fff;
}

.title-forth {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.55;
}
.title-forth_black {
  color: #000000;
}
.title-forth_white {
  color: #fff;
}
.title-forth_blue {
  color: #1A759F;
}

.paragraph, ul, li {
  font-size: 1.8rem;
  font-weight: 400;
}
.paragraph_black, ul_black, li_black {
  color: #000000;
}
.paragraph_white, ul_white, li_white {
  color: #fff;
}
.paragraph_withOpacity, ul_withOpacity, li_withOpacity {
  opacity: 0.25;
}
.paragraph_blue, ul_blue, li_blue {
  color: #1A759F;
  font-weight: 700;
}

ul {
  padding-left: 3rem;
}

.uppercaseThin {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}

.loader::after, .loader::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #1A759F;
  position: absolute;
  left: 0;
  top: 0;
  animation: loaderAnimation 1s linear infinite;
}

.loader::after {
  animation-delay: 0.5s;
}

@keyframes loaderAnimation {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.home {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(34rem, 1fr));
  overflow: hidden;
}
@media (max-width: 1110px) {
  .home {
    grid-template-columns: 66.6% 33.4%;
    height: 50vh;
  }
}
@media (max-width: 865px) {
  .home {
    grid-template-columns: 1fr 1fr;
    height: 37vh;
  }
}
@media (max-width: 575px) {
  .home {
    height: -moz-min-content;
    height: min-content;
  }
}

.item {
  font-size: 3rem;
  font-family: Poppins;
  color: #fff;
  padding-top: 4rem;
  padding-left: 4rem;
  position: relative;
}
.item__subtitle {
  margin-top: 3rem;
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: lowercase;
}
@media (max-width: 865px) {
  .item__subtitle {
    padding-right: 2rem;
    padding-left: 0;
  }
}
.item-1 {
  display: grid;
  padding: 4rem;
  background-color: #262A2E;
  grid-template-rows: min-content 1fr min-content min-content;
}
@media (max-width: 865px) {
  .item-1 {
    grid-template-columns: 10% 90%;
    grid-template-rows: min-content min-content min-content;
  }
}
.item-1__logo {
  margin-top: 3rem;
  width: 6.5rem;
  height: 6.5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 865px) {
  .item-1__logo {
    grid-row-start: 1;
    grid-row-end: -1;
  }
}
.item-1__title {
  align-self: end;
  padding-bottom: 2rem;
}
.item-1__line {
  height: 0.3rem;
  width: 60%;
  background: linear-gradient(90deg, #fff, #1A759F, #262A2E 80%);
  align-self: end;
}
.item-1__text {
  padding-top: 2rem;
  align-self: end;
}
@media (max-width: 1110px) {
  .item-1 {
    grid-row-start: 1;
    grid-row-end: 3;
    height: 50vh;
    padding: 3rem;
  }
}
@media (max-width: 865px) {
  .item-1 {
    height: 22vh;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media (max-width: 575px) {
  .item-1 {
    height: -moz-min-content;
    height: min-content;
  }
}
.item-2 {
  background-color: #1A759F;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
}
.item-2__title {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
}
.item-2__logo {
  height: 50rem;
  opacity: 0.1;
  align-self: end;
  justify-self: end;
}
@media (max-width: 1110px) {
  .item-2__logo {
    height: 43rem;
    transform: translate(3rem, -11rem);
  }
}
@media (max-width: 865px) {
  .item-2__logo {
    opacity: 0;
  }
}
@media (max-width: 1110px) {
  .item-2 {
    height: 25vh;
    padding: 3rem;
  }
}
@media (max-width: 865px) {
  .item-2 {
    height: 15vh;
    padding-left: 24%;
  }
}
@media (max-width: 575px) {
  .item-2 {
    height: 12vh;
  }
}
.item-3 {
  background-color: #fafafa;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  padding-right: 4rem;
}
.item-3__logo {
  height: 50rem;
  opacity: 0.1;
  align-self: end;
  justify-self: flex-start;
  transform: translate(-4rem, 0rem);
}
@media (max-width: 1110px) {
  .item-3__logo {
    opacity: 0;
  }
}
.item-3__title {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #1A759F;
}
@media (max-width: 865px) {
  .item-3__title {
    color: #fff;
  }
}
.item-3__subtitle {
  margin-top: 3rem;
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: lowercase;
  color: #1A759F;
}
@media (max-width: 865px) {
  .item-3__subtitle {
    color: #fff;
  }
}
.item-3__icon {
  padding-left: 2rem;
  transition: 0.5s ease-out;
  transform: translateY(-0.5rem);
}
@media (max-width: 865px) {
  .item-3__icon {
    color: #fff !important;
  }
}
@media (max-width: 1110px) {
  .item-3 {
    height: 25vh;
    grid-column-start: 2;
    grid-column-end: 3;
    padding: 3rem;
  }
}
@media (max-width: 865px) {
  .item-3 {
    height: 15vh;
    background-color: #1A759F;
  }
}
@media (max-width: 575px) {
  .item-3 {
    height: 12vh;
  }
}

.lng {
  height: 3rem;
  color: #262A2E;
  text-align: end;
  font-size: 2.2rem;
  transition: 0.25s ease-out;
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 10;
}
.lng__activ {
  font-weight: 700;
}
.lng__nonActive {
  color: #1A759F;
  cursor: pointer;
}
@media (max-width: 865px) {
  .lng__nonActive {
    color: #fafafa;
  }
}
@media (max-width: 865px) {
  .lng {
    color: #fff;
  }
}

.item-3__title:hover .item-3__icon {
  transform: translateY(1.5rem);
}

.item-2__title:hover .item-3__icon {
  transform: translateY(1.5rem);
}

.lng__nonActive:hover {
  color: #262A2E;
  font-weight: 500;
}

.help {
  display: grid;
  grid-template-columns: repeat(3, minmax(34rem, 1fr));
}
@media (max-width: 1110px) {
  .help {
    grid-template-columns: 33.3% 33.3% 33.4%;
    height: -moz-min-content;
    height: min-content;
  }
}
@media (max-width: 865px) {
  .help {
    grid-template-rows: min-content 1fr 1fr;
    grid-template-columns: auto;
    height: -moz-min-content;
    height: min-content;
  }
}
.help__item {
  padding: 4rem;
  background-color: #fafafa;
}
.help__item__box {
  background-color: #262A2E;
  grid-area: 1/3/-1/4;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 865px) {
  .help__item__box {
    grid-area: 1/1/2/2;
    background-color: #fafafa;
  }
}
@media (max-width: 865px) {
  .help__item__box h2 {
    color: #262A2E;
  }
}

.contacts {
  padding: 11.5rem 4rem;
  display: grid;
  justify-content: end;
  grid-gap: 1.5rem;
  justify-items: end;
  margin-top: 1.5rem;
  position: relative;
  background: linear-gradient(90deg, #262A2E 20%, #1A759F, #fff);
  overflow: hidden;
  height: 50vh;
}
@media (max-width: 1110px) {
  .contacts {
    height: 35vh;
    padding: 4rem;
  }
}
@media (max-width: 865px) {
  .contacts {
    height: 20vh;
  }
}
@media (max-width: 575px) {
  .contacts {
    height: 15vh;
  }
}
.contacts__items {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  grid-gap: 3rem;
}
.contacts__item img {
  height: 4rem;
}
.contacts__logo {
  display: flex;
  position: absolute;
  left: 50%;
  top: -15vh;
  height: 80vh;
  width: 80vh;
  opacity: 0.1;
  transform: translate(-50%, 0%);
}
@media (max-width: 575px) {
  .contacts__logo {
    transform: translate(-48%, 0%);
    height: 120%;
  }
}

.portfolio {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(34rem, 1fr));
  grid-template-rows: repeat(2, minmax(50vh, 1fr));
}
@media (max-width: 1110px) {
  .portfolio {
    grid-template-columns: 1fr 2fr;
  }
}
@media (max-width: 865px) {
  .portfolio {
    grid-template-rows: min-content min-content;
    grid-template-columns: auto;
  }
}
@media (max-width: 575px) {
  .portfolio {
    grid-template-rows: min-content min-content;
  }
}
.portfolio__item {
  grid-area: 1/1/-1/2;
  background-color: #262A2E;
  display: grid;
  grid-template-rows: 50% min-content 50%;
  padding: 4rem;
}
@media (max-width: 865px) {
  .portfolio__item {
    grid-area: 1/1/2/2;
    height: 10rem;
    background-color: #fafafa;
    margin-top: 5rem;
  }
}
@media (max-width: 575px) {
  .portfolio__item {
    padding: 3rem;
  }
}
.portfolio__item h3 {
  align-self: end;
  padding-bottom: 2rem;
}
@media (max-width: 865px) {
  .portfolio__item h3 {
    color: #262A2E;
  }
}
.portfolio__item p {
  align-self: start;
  padding-top: 2rem;
}
@media (max-width: 865px) {
  .portfolio__item p {
    color: #262A2E;
  }
}
.portfolio__box {
  grid-area: 1/2/-1/4;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-template-rows: repeat(2, max-content);
  grid-gap: 2rem;
  justify-content: center;
  align-content: center;
  padding: 4rem;
  justify-items: center;
}
@media (max-width: 865px) {
  .portfolio__box {
    grid-area: 2/1/3/2;
  }
}
.portfolio__box__logo {
  display: flex;
  justify-content: center;
  filter: grayscale(1);
}
.portfolio__box__logo:hover {
  filter: grayscale(0);
}
.portfolio__box__company {
  width: 20rem;
  height: 20rem;
  background-color: #fff;
  display: grid;
  grid-template-rows: 1fr min-content;
  padding: 1rem;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.portfolio__box__company:hover {
  border: 2px solid #1A759F;
}
.portfolio__box__company img {
  width: 70%;
  align-self: center;
}
.portfolio__box__endurance {
  width: 50% !important;
}
.portfolio__box__company p {
  text-align: center;
  color: #1A759F;
}

.skills {
  height: 50vh;
  text-align: end;
  margin: 1.5rem 0;
  padding: 7rem 4rem;
  padding-left: 10rem;
  display: grid;
  align-content: center;
  grid-row-gap: 2rem;
  background: linear-gradient(90deg, #fff, #1A759F, #262A2E 80%);
}
@media (max-width: 1110px) {
  .skills {
    height: -moz-min-content;
    height: min-content;
    padding: 5rem 4rem;
  }
}
@media (max-width: 865px) {
  .skills {
    height: -moz-min-content;
    height: min-content;
    padding: 4rem;
  }
}
@media (max-width: 575px) {
  .skills {
    height: -moz-min-content;
    height: min-content;
  }
}
.skills__row1 {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 15% 35% 50%;
}
@media (max-width: 1110px) {
  .skills__row1 {
    grid-template-columns: 15% 15% 70%;
  }
}
.skills__row2 {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 10vw);
}
@media (max-width: 575px) {
  .skills__row2 {
    grid-template-columns: repeat(auto-fit, 14vw);
    justify-items: center;
  }
}
.skills__line {
  height: 0.3rem;
  width: 60%;
  background: linear-gradient(90deg, #fff, #1A759F, #262A2E 80%);
  align-self: end;
  display: inline-block;
}
.skills__bigLogo {
  height: 14rem;
  width: 14rem;
  align-self: center;
  filter: saturate(0) contrast(2) brightness(0.7);
  opacity: 0.5;
}
@media (max-width: 1110px) {
  .skills__bigLogo {
    height: 10rem;
    width: 10rem;
  }
}
@media (max-width: 575px) {
  .skills__bigLogo {
    justify-self: center;
  }
}
.skills__title {
  grid-area: 1/3/1/-1;
}
.skills__item {
  height: 5rem;
  filter: saturate(0) contrast(2) brightness(0.3);
  opacity: 0.5;
}
@media (max-width: 1110px) {
  .skills__item {
    filter: saturate(0) contrast(2) brightness(2);
  }
}

.skills p {
  padding-top: 2rem;
}

.skills h3 {
  padding-bottom: 2rem;
}

.clients {
  display: grid;
  height: 50vh;
  grid-template-columns: repeat(3, minmax(34rem, 1fr));
}
@media (max-width: 865px) {
  .clients {
    grid-template-columns: repeat(3, minmax(25rem, 1fr));
    grid-template-rows: 8vh 1fr;
    height: 33vh;
  }
}
@media (max-width: 575px) {
  .clients {
    grid-template-columns: repeat(3, minmax(10rem, 1fr));
    grid-template-rows: 6vh 1fr;
    height: -moz-min-content;
    height: min-content;
  }
}
.clients__item {
  padding: 4rem;
  background-color: #1A759F;
  grid-area: 1/2/-1/-1;
}
.clients__item__box {
  transform: translateY(25%);
}
@media (max-width: 865px) {
  .clients__item__box {
    transform: translateY(-5%);
  }
}
@media (max-width: 865px) {
  .clients__item {
    grid-area: 2/1/-1/-1;
    height: 25vh;
    padding: 2rem 4rem;
  }
}
@media (max-width: 575px) {
  .clients__item {
    height: -moz-min-content;
    height: min-content;
  }
}
.clients__box {
  background-color: #fafafa;
  grid-area: 1/1/-1/2;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 865px) {
  .clients__box {
    grid-area: 1/1/-1/-1;
    height: 8vh;
    background-color: #1A759F;
  }
}
@media (max-width: 575px) {
  .clients__box {
    height: 6vh;
  }
}
@media (max-width: 865px) {
  .clients__box h3 {
    color: #fff;
  }
}
.clients__slider {
  display: grid !important;
  grid-template-columns: 11rem 80% !important;
  grid-gap: 2.5rem !important;
}
@media (max-width: 865px) {
  .clients__slider {
    grid-template-columns: 9rem 80% !important;
    grid-template-rows: 10rem 9rem;
    position: relative;
    width: 100% !important;
    height: 21rem !important;
  }
}
@media (max-width: 575px) {
  .clients__slider {
    height: -moz-min-content !important;
    height: min-content !important;
    grid-gap: 4rem !important;
    grid-row-gap: 9rem !important;
  }
}
.clients__slider__icon {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  z-index: 20;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}
@media (max-width: 865px) {
  .clients__slider__icon {
    grid-area: 2/1/3/2;
    display: inline-grid;
    height: 8rem;
    width: 8rem;
    justify-self: center;
    align-self: center;
  }
}
.clients__slider__background {
  background-color: #fff;
  height: 11rem;
  width: 11rem;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
@media (max-width: 865px) {
  .clients__slider__background {
    height: 9rem;
    width: 9rem;
  }
}
.clients__slider span {
  grid-area: 2/2/3/3;
}
@media (max-width: 865px) {
  .clients__slider span {
    justify-self: start;
    align-self: center;
  }
}
@media (max-width: 865px) {
  .clients__slider div {
    grid-area: 1/1/-1/-1;
  }
}

.projectWrap {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.project {
  font-size: 3rem;
  padding: 4rem;
  background-color: #1A759F;
  display: grid;
  grid-template-rows: 1fr min-content min-content;
}
@media (max-width: 1250px) {
  .project {
    grid-column-start: 1;
    grid-column-end: -1;
  }
}
.project__logo {
  width: 6.5rem;
  height: 6.5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: brightness(0.8);
  margin-bottom: 4rem;
}
.project__projectLogo {
  width: 10rem;
  height: 3rem;
  filter: grayscale(1);
  align-self: end;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.project__title {
  margin-bottom: 2rem;
  align-self: end;
}
.project__line {
  height: 0.3rem;
  width: 60%;
  background: linear-gradient(90deg, #fff, #1A759F, #262A2E);
  align-self: end;
}
.project__text {
  padding-top: 2rem;
  width: 50vw;
  font-size: 1.8rem;
}
@media (max-width: 1110px) {
  .project__text {
    width: 70vw;
  }
}

.mockups {
  height: -moz-min-content;
  height: min-content;
  font-size: 3rem;
  display: grid;
  position: relative;
  background-color: #fafafa;
  height: 80vh;
  grid-template-columns: 2fr 1fr;
}
@media (max-width: 1250px) {
  .mockups {
    grid-template-columns: auto;
    grid-template-rows: min-content min-content;
    height: auto;
  }
}
.mockups__images {
  padding: 3rem;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
  height: 60vh;
  width: 65vw;
  align-items: center;
  justify-self: center;
}
@media (max-width: 1250px) {
  .mockups__images {
    width: 90vw;
    height: inherit;
  }
}
@media (max-width: 1110px) {
  .mockups__images {
    height: auto;
  }
}
@media (max-width: 575px) {
  .mockups__images {
    height: -moz-min-content;
    height: min-content;
  }
}
.mockups__text {
  background-color: #262A2E;
  padding: 6rem;
  height: 80vh;
  font-size: 1.8rem;
  display: grid;
  align-items: center;
}
@media (max-width: 1250px) {
  .mockups__text {
    height: -moz-min-content;
    height: min-content;
    text-align: justify;
    font-size: 1.5rem;
  }
}
@media (max-width: 865px) {
  .mockups__text {
    font-size: 1.2rem;
  }
}
.mockups__line {
  display: flex;
  z-index: 1;
  height: 6rem;
  width: 100vw;
  margin-top: 2rem;
  background: linear-gradient(90deg, #fff, #1A759F, #262A2E 80%);
  align-self: end;
}
@media (max-width: 1250px) {
  .mockups__line {
    display: none;
  }
}
@media (max-width: 1110px) {
  .mockups__line {
    bottom: 40%;
  }
}

.stack {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #fafafa;
  padding: 6rem 4rem;
  row-gap: 3rem;
}
.stack__logo {
  width: auto;
  height: 4rem;
  justify-self: center;
  align-self: center;
  filter: grayscale(1);
}
.stack__logo:hover {
  filter: grayscale(0);
}
.stack__title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nextPrev {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding: 4rem 6rem;
  transition: 0.5s ease-out;
}
@media (max-width: 575px) {
  .nextPrev {
    padding: 4rem;
  }
}
.nextPrev__linkPrev {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: min-content max-content;
  z-index: 2;
  cursor: pointer;
  text-decoration: none;
}
.nextPrev__linkNext {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: max-content min-content;
  z-index: 2;
  cursor: pointer;
  text-decoration: none;
  justify-content: end;
}
.nextPrev__icon {
  font-size: 2rem;
  color: #fff;
  display: flex;
  align-self: center;
  transition: 0.5s ease-out;
}
.nextPrev__text {
  display: flex;
  align-self: center;
}
.nextPrev__text__next {
  justify-self: flex-end;
}
.nextPrev__line {
  display: flex;
  position: absolute;
  z-index: 1;
  bottom: 50%;
  transform: translateY(50%);
  height: 6rem;
  width: 100%;
  background-color: #1A759F;
  align-self: end;
}

.nextPrev__linkPrev:hover .nextPrev__icon {
  color: #BCE6FA;
  transform: translateX(-40%);
}

.nextPrev__linkPrev:hover .nextPrev__text {
  color: #BCE6FA;
}

.nextPrev__linkNext:hover .nextPrev__icon {
  color: #BCE6FA;
  transform: translateX(40%);
}

.nextPrev__linkNext:hover .nextPrev__text {
  color: #BCE6FA;
}/*# sourceMappingURL=style.css.map */